import { cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormTextarea,
  CFormLabel,
  CRow,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Controller, useForm, useWatch } from 'react-hook-form'
import 'simplebar/dist/simplebar.min.css'
import { object } from 'yup'
import { defaultValuesMap, validationMap } from './formUtils'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export const DynamicForm = ({ items, initialValue, onSubmit, onChange }) => {
  const itemsSchema = items.reduce((res, field) => {
    res[field.name] = validationMap[field.type]
    return res
  }, {})

  const itemsDefaultValues = items.reduce((res, field) => {
    // console.log('field: ', field)
    res[field.name] = initialValue?.[field.name] ?? defaultValuesMap[field.type]
    // console.log('res: ', res)
    return res
  }, {})

  const [progressive, setProgressive] = useState(1)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [stateInited, setStateInited] = useState(false)

  // console.log('initialValue: ', initialValue)
  // console.log('itemsDefaultValues: ', itemsDefaultValues)

  const resolverSchema = object(itemsSchema).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(resolverSchema),
    defaultValues: itemsDefaultValues,
  })

  const watchFormFields = useWatch({ control })

  useEffect(() => {
    // console.log(watchFormFields)
    if (onChange) onChange(watchFormFields)
  }, [watchFormFields])

  const makeItemField = (item, onChange, initialValue) => {
    if (item.type === 'textarea' && !stateInited) {
      const contentBlock = htmlToDraft(initialValue ?? '')
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(EditorState.createWithContent(contentState))
        setStateInited(true)
      }
    }
    return (<>
      {item?.label && (
        <CFormLabel className="me-2" htmlFor={item.name + '-field'}>
          {item.label}
        </CFormLabel>
      )}
      {item.type !== 'boolean' && item.type !== 'textarea' ? (
        <CFormInput
          // disabled={!editable}
          id={item.name + '-field'}
          defaultValue={initialValue}
          type={item.type}
          rows={10}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : item.type === 'textarea' ? (
        // <CFormTextarea
        //   id={item.name + '-field'}
        //   defaultValue={initialValue}
        //   onChange={(e) => onChange(e.target.value)}
        // />
        <Editor
          id={item.name + '-field'}
          editorState={editorState}
          onEditorStateChange={(e) => {
            const html = draftToHtml(convertToRaw(e.getCurrentContent()))
            setEditorState(e)
            onChange(html)
          }}
        />
      ) : (
        <CFormCheck
          id={item.name + '-field'}
          defaultChecked={initialValue}
          // disabled={!editable}
          onChange={(e) => onChange(e.target.checked)}
        />
      )}
    </>)
  }

  function submit(v) {
    onSubmit(v)
  }

  const itemsHtml = items.map((item, index) => (
    <Controller
      key={index}
      render={({ field }) => {
        return (
          <>
            {item.type !== 'array' ? (
              <>
                {makeItemField(item, field.onChange, field.value)}
                <CCol xs={12}>
                  <span>{errors[field.name]?.message}</span>
                </CCol>
              </>
            ) : (
              <div className="dynamicForm-array-container">
                <span>{item.label}</span>
                {getValues()[item.name].map((value, itemArrayIndex) => (
                  <CRow key={progressive} className="mb-2">
                    {item.nestedItems.map((nestedItem, nestedIndex) => (
                      <CCol xs="auto" key={nestedIndex}>
                        {makeItemField(
                          nestedItem,
                          (nestedValue) => {
                            // let itemObj = getValues()[item.name]
                            // let newValue = value
                            // console.log(newValue)
                            let newArray = getValues()[item.name]
                            newArray[itemArrayIndex][nestedItem.name] = nestedValue
                            // newValue[itemArrayIndex][nestedItem.name] = nestedValue
                            field.onChange(newArray)
                          },
                          value[nestedItem.name],
                        )}
                      </CCol>
                    ))}
                    <CCol>
                      <CButton
                        color="danger"
                        onClick={() => {
                          const newVal = [...getValues()[item.name]]
                          newVal.splice(itemArrayIndex, 1)
                          field.onChange(newVal)
                        }}
                      >
                        <CIcon icon={cilTrash} />
                      </CButton>
                    </CCol>
                  </CRow>
                ))}
                <CButton
                  className="dynamic-form-new-array-item-button"
                  onClick={() => {
                    const newVal = [...getValues()[item.name], {}]
                    field.onChange(newVal)
                  }}
                >
                  <CIcon icon={cilPlus} />
                </CButton>
                <CCol xs={12}>
                  <span>{errors[field.name]?.message}</span>
                </CCol>
              </div>
            )}
          </>
        )
      }}
      name={item.name}
      control={control}
    />
  ))

  return (
    <CForm onSubmit={handleSubmit(submit)}>
      {itemsHtml}
      {onSubmit && <CButton type="submit">Salva</CButton>}
    </CForm>
  )
}

DynamicForm.propTypes = {
  items: PropTypes.array.isRequired,
  initialValue: PropTypes.object,
  editable: PropTypes.bool,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
}
